export const dizhuangDetail = [
	{
		id: 1,
		defalut: require('@/assets/img/底妆素材/产品模特图/BKFB1.jpg'),
		active: require('@/assets/img/底妆素材/产品模特图/BKFB2.jpg'),
		series: '底妆',
		seriesName: '贝壳粉饼',
		zhName: '酵色「贝壳粉饼」',
		enName: 'Spiral Shell Amplifier Powder',
		intro: 'Q弹帖肤不卡粉 清透柔焦伪素颜',
		price: '129',
		of: '10g',
		imgUrl: [
			{ index: 1, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/1.jpg') },
			{ index: 2, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/2.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/3.jpg') },
			{ index: 3, url: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg'), bigUrl: require('@/assets/img/详情/贝壳素材/贝壳粉饼/4.jpg') },
		],
		color: [
			{
				active: '#f1e8d9',
				name: '#01 自然色',
				span1: '为亚洲肤色定制',
				span2: '奶油淡黄色粉体 遮瑕修肤',
				color: require('@/assets/img/详情/贝壳素材/贝壳粉饼/001.jpg')
			}
		]
	},
	{
		id: 2,
		defalut: require('@/assets/img/底妆素材/产品模特图/ZX1.jpg'),
		active: require('@/assets/img/底妆素材/产品模特图/ZX2.jpg'),
		series: '底妆',
		seriesName: '小奶条遮瑕',
		zhName: '酵色「小奶条遮瑕」',
		enName: 'Winter Sweet Tip Concelear',
		intro: '轻盈细腻 高级光泽',
		price: '69',
		of: '4.5g',
		imgUrl: [
			// E:\joocyee\joocyee-fronted\src\assets\img\详情\遮瑕
			{ index: 1, url: require('@/assets/img/详情/遮瑕/01.jpg'), bigUrl: require('@/assets/img/详情/遮瑕//001.jpg') },
			{ index: 2, url: require('@/assets/img/详情/遮瑕/02.jpg'), bigUrl: require('@/assets/img/详情/遮瑕/002.jpg') },
			{ index: 3, url: require('@/assets/img/详情/遮瑕/03.jpg'), bigUrl: require('@/assets/img/详情/遮瑕/003.jpg') },
			{ index: 3, url: require('@/assets/img/详情/遮瑕/04.jpg'), bigUrl: require('@/assets/img/详情/遮瑕/004.jpg') },
		],
		color: [
			{
				active: '#f7e9dc',
				name: '#0.5 白皙色',
				span1: '适合面部提亮或泪沟遮瑕',
				span2: '   ',
				color: require('@/assets/img/详情/遮瑕/0.5.jpg')
			},
			{
				active: '#f3dfcd',
				name: '#1 亮肤色',
				span1: '适合偏白肤色遮瑕',
				span2: ' ',
				color: require('@/assets/img/详情/遮瑕/1.jpg')
			},
			{
				active: '#eed7c3',
				name: '#2 自然色',
				span1: '适合自然偏黄肤色遮瑕',
				span2: ' ',
				color: require('@/assets/img/详情/遮瑕/2.jpg')
			},
			{
				active: '#dfc3af',
				name: '#3 裸橘色',
				span1: '适合用在粉底前 中和黑眼圈',
				span2: ' ',
				color: require('@/assets/img/详情/遮瑕/3.jpg')
			},
		]
	},
]